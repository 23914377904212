<template>
  <div>
    <div><h1>Users &#38; Permissions</h1></div>
    <hr>
    <b-button
      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
      type="button"
      variant="link"
      style="margin-left: 1rem"
      class="btn-link-box"
      @click="openUsersPage()"
    >
      Users
    </b-button>
    <br>
    <b-button
      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
      type="button"
      variant="link"
      style="margin-left: 1rem"
      class="btn-link-box"
      @click="openUserRolesPage()"
    >
      User Roles
    </b-button>
    <br v-if="getCurrentScopes.indexOf('st2.changeuserpermset') !== -1">
    <b-button
      v-if="getCurrentScopes.indexOf('st2.changeuserpermset') !== -1"
      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
      type="button"
      variant="link"
      style="margin-left: 1rem"
      class="btn-link-box"
      @click="openPermissionSetsPage()"
    >
      Permission Sets
    </b-button>
  
  </div>
</template>


<script>
import { BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import "vue-good-table/dist/vue-good-table.css";
import store from "@/store";
import {mapGetters} from "vuex";



export default {
  components: {

    BButton,
    // eslint-disable-next-line vue/no-unused-components
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapGetters({
      getCurrentScopes: "scopes/getCurrentScopes",
    }),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
  },
  methods: {
    openUsersPage() {
      this.$router.push('/admin/users-and-permissions/users')
    },
    openUserRolesPage() {
      this.$router.push('/admin/users-and-permissions/user-roles')
    },
    openPermissionSetsPage() {
      this.$router.push('/admin/users-and-permissions/permission-sets')
    }
  }
}
</script>

<style>
  .btn-link-box {
    border-color: #476dae !important;
    margin-top: 1rem;
    width: 160px;
  }
</style>
